<template>
  <div></div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  name: "Dashboard",
  components: {},
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  created() {
    this.succesMessage();
  },
  methods: {
    ...mapActions([
      "getUserplanlist",
      "addToCart12",
      "PayuPaymentDetail",
      "PaymentResponceFailed",
    ]),

    succesMessage() {
      let message = "Verification Link Send To Registered Email";
      let title = "Registered Successfully";
      let type = "success";
      this.$alert(message, title, type).then(() => {
        window.location.href = "/";
      });
    },
  },
};
</script>